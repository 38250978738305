import React from 'react';
import { observer } from "mobx-react-lite";
import { Radio, RadioGroup, Modal, Button, Input } from 'rsuite';
import { testEmail } from './URLCleanupUtil';

const QuestionPopup = observer(({ interfaceStateStore, serverSynchronization }) => {
	const url = interfaceStateStore.showingQuestionLinkURL;
	const selectedAnswer = interfaceStateStore.showingQuestionSelectedAnswer;
	const emailAddress = interfaceStateStore.showingQuestionEmailAddress;
	
	function submitPopup() {
		if(!selectedAnswer) {
			alert("Please select an answer to continue.");
			return;
		}
		if(url.requireEmail) {
			if(!emailAddress || !testEmail(emailAddress)) {
				alert("Please enter a valid email address to continue.");
				return;
			}
		}
		
		const emailAddressToSend = url.requireEmail ? emailAddress : "";
		serverSynchronization.logQuestionAnswered(url.questionText, selectedAnswer, emailAddressToSend);
		interfaceStateStore.setShowingQuestionLinkURL(null);
		interfaceStateStore.setShowingQuestionSelectedAnswer("");
		// We won't clear the email address, it'll be re-used for any other polls on the page
	}
	function cancelPopup() {
		interfaceStateStore.setShowingQuestionLinkURL(null);
		interfaceStateStore.setShowingQuestionSelectedAnswer("");
	}
	
	if(!url) {
		return null;
	} else {
		let emailField = null;
		if(url.requireEmail) {
			emailField = (
				<>
					<div className="edit-panel-field">
						<label>Email address:</label>
						<Input
							value={emailAddress}
							onChange={value => interfaceStateStore.setShowingQuestionEmailAddress(value)}
						>
						</Input>
					</div>
					<div className="edit-panel-field">
						<label className="disclaimer">By submitting your email, you are providing your data to {interfaceStateStore.bioAuthorName} and BossLady Bio, who may contact you.</label>
					</div>
				</>
			);
		}
		return (
			<Modal show={true} className='edit-panel question-popup' backdrop={true} overflow={false}>
				<Modal.Body className="edit-panel-body">
					<h1>{url.questionText}</h1>
					<RadioGroup
						value={selectedAnswer}
						onChange={value => interfaceStateStore.setShowingQuestionSelectedAnswer(value)}
					>
						{
							url.answers.map(answer => 
								<div className="edit-panel-field">
									<Radio value={answer}>{answer}</Radio>
								</div>
							)
						}
					</RadioGroup>
					{ emailField }
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={submitPopup} appearance="primary" className="done-button">Submit</Button>
					<Button onClick={cancelPopup} appearance="primary" className="cancel-button">Cancel</Button>
				</Modal.Footer>
			</Modal>
		);
	}
});
 
export default QuestionPopup;