import React from 'react';
import { observer } from "mobx-react-lite";
import {themeSelectedForCheckout, activateMyBioWithThemeTheme} from './SelectedThemeCookieUtil';
import SaveStateLabel from './SaveStateLabel';
import { RetiredThemes } from './Stores';

const Toolbar = observer(({ bioStore, interfaceStateStore }) => {
	if(!interfaceStateStore.isEditing && !interfaceStateStore.shoppingTheme && !interfaceStateStore.myBioWithTheme) return null;

	function themeSwitcherToggle() {
		if(!interfaceStateStore.themeGalleryOpen) {
			// Check for retired themes	before opening to warn them that they won't be able to switch back
			if(RetiredThemes.indexOf(bioStore.theme) !== -1) {
				if(!window.confirm("If you select another theme, you won't be able to use your current theme in the future.")) return;
			}
		}
		interfaceStateStore.setThemeGalleryOpen(!interfaceStateStore.themeGalleryOpen);
		if(interfaceStateStore.themeGalleryOpen) window.scrollTo(0, 0);
	}
	
	function openMyAccount() {
		window.location = '/my-account/';	
	}
	
	function previewClick() {
		if(interfaceStateStore.hasSetURL) {
			interfaceStateStore.setDoneScreenPhase("none");
			interfaceStateStore.setPreviewingForShare(true);
		} else {
			interfaceStateStore.setPreviewingForShare(false);
			const qualityCheckStatus = bioStore.getQualityCheckStatus();
			const keys = Object.keys(qualityCheckStatus);
			if(keys.length === 0) {
				interfaceStateStore.setDoneScreenPhase("done");
			} else {
				interfaceStateStore.setDoneScreenPhase("qualityCheck");	
			}
		}
	}

	function backClick() {
		interfaceStateStore.setShowingSharePopup(false);
		interfaceStateStore.setPreviewingForShare(false);
	}
	
	function backToGallery() {
		interfaceStateStore.setThemeGalleryOpen(true);
		interfaceStateStore.setPreviewingTheme(null, false);
	}
	
	function backToShop() {
		window.location = "/";
	}

	function activateTheme() {
		if(!interfaceStateStore.hasPaidPlan && interfaceStateStore.previewingTheme !== "basic") {
			themeSelectedForCheckout(interfaceStateStore.previewingTheme);
		} else {
			bioStore.setTheme(interfaceStateStore.previewingTheme, interfaceStateStore.previewingThemeIsOverriddenTheme);
			interfaceStateStore.setPreviewingTheme(null, false);
			window.scrollTo(0, 0);				
		}
	}
	
	function activateShoppingTheme() {
		themeSelectedForCheckout(interfaceStateStore.shoppingTheme);
	}
	
	function getButtons() {
		if(interfaceStateStore.previewingTheme) {
			let activateThemeLabel = "Pick this theme";
			if(!interfaceStateStore.hasPaidPlan && interfaceStateStore.previewingTheme !== "basic") activateThemeLabel = "Buy Now (Pro Theme)";
			return (
				<React.Fragment>
					<button onClick={backToGallery}>Back to Gallery</button>
					<button className="activate-theme-button" onClick={activateTheme}>{activateThemeLabel}</button>
				</React.Fragment>
			)
		} else if(interfaceStateStore.shoppingTheme) {
			const activateThemeLabel = (interfaceStateStore.shoppingTheme === "basic") ? "Sign up Now" : "Sign Up";
			return (
				<React.Fragment>
					<button onClick={backToShop}>Back</button>
					<button className="activate-theme-button" onClick={activateShoppingTheme}>{activateThemeLabel}</button>
				</React.Fragment>
			)
		} else if(interfaceStateStore.previewingForShare) {
			return (
				<React.Fragment>
					<button onClick={backClick}>Back</button>
				</React.Fragment>
			);
		} else if(interfaceStateStore.myBioWithTheme) {
			const buttonLabel = interfaceStateStore.hasPaidPlan ? 'Switch my bio to new theme' : 'Upgrade now for new theme';
			return (
				<React.Fragment>
					<button className="my-account-button" onClick={openMyAccount}>My account</button>
					<button className="activate-theme-button" onClick={() => activateMyBioWithThemeTheme(interfaceStateStore)}>{buttonLabel}</button>;
				</React.Fragment> 
			);
		} else {
			return (
				<React.Fragment>
					<SaveStateLabel interfaceStateStore={interfaceStateStore}></SaveStateLabel>
					<button onClick={themeSwitcherToggle} className="switch-theme-button">Switch Theme</button>
					<button onClick={previewClick} className="done-with-bio-button">
						{interfaceStateStore.hasSetURL ? "Preview" : "Done" }
					</button>
				</React.Fragment>
			);
		}
	}
	
	if(interfaceStateStore.themeGalleryOpen) {
		return null;
	} else {
		return (
			<nav className="editor-toolbar">
				{ getButtons() }
			</nav>
		);
	}
});
 
export default Toolbar;
 