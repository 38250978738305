import React from 'react';
import { observer } from "mobx-react-lite";
import { Button } from 'rsuite';

const URLMap = {
	'bio': '/bio-tips',
	'quickclick': '/quickclick-tips',
	'quiz': '/quiz-tips',
	'informational': 'other-tips'	
};
const FragmentMap = {
	"intro-photo": "intro-text",
	"intro-text": "intro-text",
	"video": "intro-text",
	"cta-button": "intro-text",
	"link": "link",
	"carousel-card": "carousel-card",
	"testimonial": "testimonial",
	"contact-details": "contact-details",
	"contact-social-media-list": "contact-details"
}


const TipsButton = observer(({primaryGoal, itemType}) => {
	const url = `https://bossladybio.com/${URLMap[primaryGoal]}#${FragmentMap[itemType]}`;
	
	return (
		<a className="tips-button" href={url} target="_blank" rel="noreferrer">
			<Button appearance="primary">Helpful Tips</Button>
		</a>	
	);
});
 
export default TipsButton;
