import { onSnapshot } from 'mobx-state-tree';
import { linkURLTypeString, getPhotoPropertiesForCardIndex } from './Stores';

const SaveAttemptInterval = 1000; // Check every 1 seconds to see if we should save the latest changes
const EditorNeedsReloadingInterval = 30000; // Check every 30 seconds to see if we've been logged out or the bio has been modified elsewhere


export default class ServerSynchronization {
	#bioStore;
	#interfaceStateStore;
	#revisionNumber;
	#lastSavedRevisionNumber;
	#saveURL;
	#videoUploadURL;
	#photoUploadURL;
	#fileUploadURL;
	#fileDeleteURL;
	#mediaURL;
	#uploadDisplayURL;
	#bioURL;
	#logViewURL;
	#logActionURL;
	#getEditorNeedsReloadingStatusURL;
	#cardPhotoSwapURL;
	#cardPhotoDeleteURL;
	#getVCardURL;
	#logQuestionAnsweredURL;
	#setBioURLURL;
	#setSharedStatusURL;
	#latestSnapshot = null;
	#isSaving = false;
	#isReloading = false;
	
	constructor(bioStore, interfaceStateStore, revisionNumber, saveURL, videoUploadURL, photoUploadURL, fileUploadURL, fileDeleteURL, mediaURL, uploadDisplayURL, bioURL, logViewURL, logActionURL, getEditorNeedsReloadingStatusURL, cardPhotoSwapURL, cardPhotoDeleteURL, getVCardURL, logQuestionAnsweredURL, setBioURLURL, setSharedStatusURL) {
		this.#bioStore = bioStore;
		this.#interfaceStateStore = interfaceStateStore;
		this.#revisionNumber = revisionNumber;
		this.#lastSavedRevisionNumber = revisionNumber;
		this.#saveURL = saveURL;
		this.#videoUploadURL = videoUploadURL;
		this.#photoUploadURL = photoUploadURL;
		this.#fileUploadURL = fileUploadURL;
		this.#fileDeleteURL = fileDeleteURL;
		this.#mediaURL = mediaURL;
		this.#uploadDisplayURL = uploadDisplayURL;
		this.#bioURL = bioURL;
		this.#logViewURL = logViewURL;
		this.#logActionURL = logActionURL;
		this.#setBioURLURL = setBioURLURL;
		this.#setSharedStatusURL = setSharedStatusURL;
		this.#getEditorNeedsReloadingStatusURL = getEditorNeedsReloadingStatusURL;
		this.#cardPhotoSwapURL = cardPhotoSwapURL;
		this.#cardPhotoDeleteURL = cardPhotoDeleteURL;
		this.#getVCardURL = getVCardURL;
		this.#logQuestionAnsweredURL = logQuestionAnsweredURL;
		
		if(interfaceStateStore.isEditing) { // This will need to be updated if we ever switch to them being able to dynamically switch between editor/viewer modes
			onSnapshot(bioStore, this.newSnapshot.bind(this));
			setInterval(this.attemptSave.bind(this), SaveAttemptInterval);
			setInterval(this.getEditorNeedsReloadingStatus.bind(this), EditorNeedsReloadingInterval);
			document.addEventListener("visibilitychange", this.getEditorNeedsReloadingStatus.bind(this));
		}
	}
	
	logView() {
		ServerSynchronization.sendBeacon(this.#logViewURL, this.#interfaceStateStore);
	}
	
	isSaved() {
		return this.#lastSavedRevisionNumber === this.#revisionNumber;
	}
	
	logAction(type, targetTitle) {
		if(!this.#logActionURL) return;
		const formData = new FormData();
		formData.append("type", type);
		formData.append("targetTitle", targetTitle || '');
		ServerSynchronization.sendBeacon(this.#logActionURL, this.#interfaceStateStore, formData);
	}
	
	static sendBeacon(url, interfaceStateStore, data) {
		if(interfaceStateStore.isEditing || interfaceStateStore.previewingTheme || interfaceStateStore.shoppingTheme || interfaceStateStore.myBioWithTheme || !navigator.sendBeacon || !url) return;
		navigator.sendBeacon(url, data);	
	}
	
	getBioURL() {
		return this.#bioURL;	
	}
	
	getMediaURL(slot, cropString, extension, revisionNumber, cacheBust) {
		let url = this.#mediaURL.replace("{slot}", slot).replace("{cropString}", cropString).replace("{extension}", extension).replace("{revisionNumber}", revisionNumber);
		if(cacheBust) url += "&" + Math.round(new Date().getTime() / 1000);
		return url;
	}
	
	getUploadDisplayURL(id, extension) {
		return this.#uploadDisplayURL.replace("{id}", id).replace("{extension}", extension);
	}
	
	newSnapshot(newSnapshot) {
		this.#revisionNumber++; 
		this.#latestSnapshot = newSnapshot;
	}
	
	getEditorNeedsReloadingStatus() {
		if(document.debug) return;
		if(document.visibilityState === "visible") {
			ServerSynchronization.runFetch(this.#getEditorNeedsReloadingStatusURL, undefined,
				data => {
					if(this.#isReloading) return;
					if(!data.isLoggedInForBio) {
						alert("You've been logged out, please log back in to continue.");	
						this.reload();
					}
					if(data.revisionNumber > this.#revisionNumber) {
						alert("Your bio appears to have been modified from another session and will now reload.");	
						this.reload();
					}
				}, 
				undefined
			);
		}
	}
	
	reload() {
		if(!this.#isReloading) {
			this.#isReloading = true;
			window.location.reload();
		}
	}
	
	attemptSave() {
		if(!this.#interfaceStateStore.currentlyEditingItemType && this.#lastSavedRevisionNumber < this.#revisionNumber && !this.#isSaving && this.#saveURL) { // Only save if they're not in the middle of editing an item, and we're not up to date, and we're not already saving, and we're not running in the dev mode with no save URLs
			this.#isSaving = true;
			this.#interfaceStateStore.setSaveState("saving");
			let revisionNumberSaving = this.#revisionNumber;
			const formData = new FormData();
			formData.append("data", JSON.stringify({ bioData: this.#latestSnapshot, revisionNumber: this.#revisionNumber }));
			ServerSynchronization.runFetch(this.#saveURL, formData,
				data => {
					this.#lastSavedRevisionNumber = revisionNumberSaving;
					console.log("Save successful for revision #" + revisionNumberSaving);
					this.#interfaceStateStore.setSaveState("saved");
				}, 
				() => {
					this.#isSaving = false;
					if(this.isSaved()) this.#interfaceStateStore.setSaveState("saved");
				},
				(error) => {
					this.#interfaceStateStore.setSaveState("error");
				}
			);
		}
	}
	
	// Uploads the video, and if successful, updates the BioStore to reflect the new video
	uploadVideo(video) {
		const formData = new FormData();
		formData.append("videoFile", video);
		this.#interfaceStateStore.setServerSyncInProgress("video");
		ServerSynchronization.runFetch(this.#videoUploadURL, formData,
			data => {
				this.#bioStore.setVideo(true, this.#bioStore.videoRevisionNumber + 1);
			}, 
			() => {
				this.#interfaceStateStore.setServerSyncInProgress(null);
				this.attemptSave();
			}
		);
	}
	
	// Uploads the file, and if successful, calls the callback with { filename, fileType, id }
	uploadFile(file, successCallback) {
		const formData = new FormData();
		formData.append("file", file);
		this.#interfaceStateStore.setServerSyncInProgress("file");
		ServerSynchronization.runFetch(this.#fileUploadURL, formData,
			data => {
				successCallback(data);
			}, 
			() => {
				this.#interfaceStateStore.setServerSyncInProgress(null);
			},
			error => {
				alert("An error occurred uploading your file:\n" + error.message);
			}
		);
	}
	
	deleteFile(id, successCallback) {
		const formData = new FormData();
		formData.append("id", id);
		this.#interfaceStateStore.setServerSyncInProgress("fileDelete");
		ServerSynchronization.runFetch(this.#fileDeleteURL, formData,
			data => {
				successCallback();
			}, 
			() => {
				this.#interfaceStateStore.setServerSyncInProgress(null);
			},
			error => {
				alert("An error occurred deleting your file:\n" + error.message);
			}
		);
	}

	uploadPhoto(photo, slot, extension, currentRevisionNumber, isCropped) {
		const formData = new FormData();
		formData.append("photo", photo);
		formData.append("data", JSON.stringify({ slot: slot, isCropped: isCropped, extension: extension }));
		this.#interfaceStateStore.setServerSyncInProgress("photo");
		ServerSynchronization.runFetch(this.#photoUploadURL, formData,
			data => {
				const props = { customization: "providedCustom", revisionNumber: currentRevisionNumber + 1 };
				if(!isCropped) props.uncroppedExtension = extension;
				this.#bioStore.updatePhoto(slot, props);
			}, 
			() => {
				this.#interfaceStateStore.setServerSyncInProgress(null);
				this.attemptSave();
			}
		);
	}
	
	swapCards(index1, index2) {
		// First swap the card photos on the server side
		const formData = new FormData();
		formData.append("data", JSON.stringify({index1: index1, index2: index2}));
		this.#interfaceStateStore.setServerSyncInProgress("cardPhotoSwap");
		ServerSynchronization.runFetch(this.#cardPhotoSwapURL, formData,
			data => {
				// Now that we've swapped the photos, swap the card data
				this.#bioStore.swapCarouselCardIndex(index1, index2);
			}, 
			() => {
				this.#interfaceStateStore.setServerSyncInProgress(null);
			}
		);
	}
	
	deleteCard(index, card) {
		// We'll first delete any photo, if applicable
		const deletePhoto = () => {
			if(getPhotoPropertiesForCardIndex(this.#bioStore, index).customization !== "providedCustom") {
				deleteCorrespondingFile(); // Move on to the next step
			} else {
				const formData = new FormData();
				formData.append("data", JSON.stringify({index: index}));
				this.#interfaceStateStore.setServerSyncInProgress("cardPhotoDelete");
				ServerSynchronization.runFetch(this.#cardPhotoDeleteURL, formData,
					data => {
						this.#interfaceStateStore.setServerSyncInProgress(null); 
						deleteCorrespondingFile();
					},
					undefined,
					() => {
						// We've encountered an error, so disable the sync-in-progress indicator
						this.#interfaceStateStore.setServerSyncInProgress(null); 
					}
				);
			}
		}
		// Second, we'll delete the corresponding uploaded file, if applicable
		const deleteCorrespondingFile = () => {
			if(linkURLTypeString(card.buttonURL) === "upload" && card.buttonURL.id) {
				this.deleteFile(card.buttonURL.id, deleteCardFromStores);	
			} else {
				// There's no upload to clean up, so delete immediately
				deleteCardFromStores();	
			}
		}
		// Finally, we'll delete the card from the stores
		const deleteCardFromStores = () => {
			this.#interfaceStateStore.setCurrentlyEditing(null, null, null);
			this.#bioStore.deleteCarouselCard(index);
			this.#interfaceStateStore.setServerSyncInProgress(null); 
		}
		
		deletePhoto();
	}
	
	upgradeToPro() {
		window.location = this.#interfaceStateStore.upgradeToProURL;
	}
	
	openMyAccount() {
		window.location = "/my-account/";
	}
	
	getVCard() {
		window.location = this.#getVCardURL;
	}

	logQuestionAnswered(question, answer, email) {
		if(!this.#logQuestionAnsweredURL) return;
		const formData = new FormData();
		formData.append("question", question);
		formData.append("answer", answer);
		formData.append("email", email);
		ServerSynchronization.sendBeacon(this.#logQuestionAnsweredURL, this.#interfaceStateStore, formData);
	}
	
	setBioURL(domain, nicename) {
		if(!this.#setBioURLURL) return;
		const formData = new FormData();
		formData.append("data", JSON.stringify({domain: domain, nicename: nicename}));	
		this.#interfaceStateStore.setServerSyncInProgress("setBioURL");
		ServerSynchronization.runFetch(this.#setBioURLURL, formData,
			data => {
				this.#interfaceStateStore.setHasSetURL(true);
				if(window.setBioURLForMyAccountTopNav) {
					// Communicate the change to the top nav
					window.setBioURLForMyAccountTopNav(`https://${domain}/${nicename}`);
				}
			}, 
			() => {
				this.#interfaceStateStore.setServerSyncInProgress(null);
			},
			error => {
				alert("An error occurred saving your link:\n" + error.message);
			}
		);
	}
	
	setSharedStatus(type) {
		if(!this.#setSharedStatusURL || !navigator.sendBeacon) return;
		const formData = new FormData();
		formData.append("data", JSON.stringify({statusType: type}));	
		navigator.sendBeacon(this.#setSharedStatusURL, formData);	
	}
	
	static runFetch(url, formData, successCallback, finallyCallback, errorCallback) {
		// If the URL is null, we're in test mode and will just simulate the request
		if(url === null) {
			setTimeout(function() {
				successCallback({});
				if(finallyCallback) finallyCallback();				
			}, 1000);
		} else {
			fetch(url, { method: 'POST', body: formData,  cache: 'no-cache' })
			.then(response => response.json())
			.then(json => {
				if(json && json.success) {
					successCallback(json.data);
				} else {
					let message = "";
					if(json && json.error) {
						message = json.error;
					} else {
						message = "Unknown error.";
					}
					throw new Error(message);	
				}
			})
			.catch(error => {
				console.error("Error during POST to " + url, error);
				if(errorCallback) errorCallback(error);
			})
			.finally(() => {
				if(finallyCallback) finallyCallback();
			});
		}
	}
}