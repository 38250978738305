import { types } from "mobx-state-tree";
import { toJS } from "mobx";
import { containsTemplateReplacementBracketedString, urlContainsTemplateReplacementBracketedString } from "./BioTemplateUtil.js";
import basicThemeProperties from "./themes/basic/basic.js";
import neutralBrushThemeProperties from "./themes/neutral-brush/neutral-brush.js";
import simpleBlackAndWhiteThemeProperties from "./themes/simple-black-and-white/simple-black-and-white.js";
import emeraldChevronThemeProperties from "./themes/emerald-chevron/emerald-chevron.js";
import rainbowLinesThemeProperties from "./themes/rainbow-lines/rainbow-lines.js";
import earthyAngleThemeProperties from "./themes/earthy-angle/earthy-angle.js";
import desertSunsetThemeProperties from "./themes/desert-sunset/desert-sunset.js";
import burgundyDreamsThemeProperties from "./themes/burgundy-dreams/burgundy-dreams.js";
import freshAmbitionThemeProperties from "./themes/fresh-ambition/fresh-ambition.js";
import vibrantVisionaryThemeProperties from "./themes/vibrant-visionary/vibrant-visionary.js";
import retroRadianceThemeProperties from "./themes/retro-radiance/retro-radiance.js";
import simplyChicThemeProperties from "./themes/simply-chic/simply-chic.js";
import modernSageThemeProperties from "./themes/modern-sage/modern-sage.js";
import neonGlowThemeProperties from "./themes/neon-glow/neon-glow.js";

const PhotoSlots = ["intro-photo", "testimonial-photo", "contact-accent-photo", "card1-photo", "card2-photo", "card3-photo"];
const Themes = [ "neon-glow", "modern-sage", "simply-chic", "retro-radiance", "vibrant-visionary", "fresh-ambition", "burgundy-dreams", "desert-sunset", "simple-black-and-white", "earthy-angle", "rainbow-lines", "neutral-brush", "emerald-chevron", "basic" ];
const RetiredThemes = [ "emerald-chevron", "rainbow-lines", "simple-black-and-white", "burgundy-dreams", "retro-radiance" ];
const PrimaryGoals = ["bio", "quickclick", "quiz", "informational"];
const DoneScreenPhase = ["none", "qualityCheck", "done"];
const IntroState = ["normal", "clone", "none"];

function sanitizeThemeValue(theme) {
	return (Themes.indexOf(theme) === -1) ? null : theme;
}
const ThemeProperties = {
	"basic": basicThemeProperties,
	"neutral-brush": neutralBrushThemeProperties,
	"simple-black-and-white": simpleBlackAndWhiteThemeProperties,
	"emerald-chevron": emeraldChevronThemeProperties,
	"rainbow-lines": rainbowLinesThemeProperties,
	"earthy-angle": earthyAngleThemeProperties,
	"desert-sunset": desertSunsetThemeProperties,
	"burgundy-dreams": burgundyDreamsThemeProperties,
	"fresh-ambition": freshAmbitionThemeProperties,
	"vibrant-visionary": vibrantVisionaryThemeProperties,
	"retro-radiance": retroRadianceThemeProperties,
	"simply-chic": simplyChicThemeProperties,
	"modern-sage": modernSageThemeProperties,
	"neon-glow": neonGlowThemeProperties
}

const UploadFileType = types.enumeration('UploadFileType', ['pdf', 'mp4', 'jpg', 'png', 'pdf']);

// Overloading our original plain text URLs with poll-question launch and uploaded file target "urls"
const LinkURLQuestion = types.model({
	type: types.literal("question"),
	questionText: types.optional(types.string, ""),
	answers: types.optional(types.array(types.string), ['', '']),
	requireEmail: types.optional(types.boolean, true)
}).actions(
	self => ({
		setQuestionText(questionText) {
			self.questionText = questionText;
		},
		updateAnswer(index, text) {
			self.answers[index] = text;	
		},
		deleteAnswer(index) {
			self.answers.splice(index, 1);
		},
		setRequireEmail(requireEmail) {
			self.requireEmail = requireEmail;	
		}
	})
);
const LinkURLUpload = types.model({
	type: types.literal("upload"),
	id: types.maybeNull(types.number), // null when not yet assigned from the server with our uploaded file's ID
	filename: types.maybeNull(types.string), // null when not yet assigned from the server with our uploaded file's filename
	fileType: types.maybeNull(UploadFileType), // null when not yet assigned from the server with our uploaded file's type
}).actions(
	self => ({
		setUploadData(id, filename, fileType) {
			self.id = id;
			self.filename = filename;
			self.fileType = fileType;
		}
	})
);
const LinkURL = types.union(
	types.string,
	LinkURLQuestion,
	LinkURLUpload
)
const Link = types.model({
	id: types.optional(types.string, createIDGenerator("Link")),
	label: types.string,
	url: LinkURL,
	treatPhoneNumberAsSMS: types.maybe(types.boolean)
});

const Photo = types.model({
	id: types.identifier, // The slot identifier of the model, currently "intro", "contact-accent", "testimonial", "card1", "card2", or "card3"
	customization: types.optional(types.enumeration(["useDefault", "providedCustom", "noPhoto"]), "useDefault"),
	revisionNumber: types.optional(types.integer, 0), // Which version of the photo we're currently "on" for cache-busting purposes
	uncroppedExtension: types.optional(types.enumeration(["jpg", "png"]), "jpg"), // The extension of the uncropped version (the cropped version will be converted to JPG until we have more intelligence built in)
	cropX: types.optional(types.integer, 0),
	cropY: types.optional(types.integer, 0),
	cropWidth: types.optional(types.integer, 0),
	cropHeight: types.optional(types.integer, 0)
});

const CarouselCard = types.model({
	id: types.optional(types.string, createIDGenerator("CarouselCard")),
	heading: types.string,
	body: types.string,
	buttonLabel: types.string,
	buttonURL: LinkURL,
	treatPhoneNumberAsSMS: types.maybe(types.boolean)
});

// The persisted state of the bio. Null is meant to signify the value is uninitialized/never-edited by the user.
const BioStore = types.model({
	schemaVersion: types.integer,
	theme: types.enumeration(Themes),
	heading: types.maybeNull(types.string),
	body: types.maybeNull(types.string),
	hasVideo: types.optional(types.boolean, false),
	videoRevisionNumber: types.optional(types.integer, 0), /* Which version of the video we're currently "on" for cache-busting purposes */
	ctaButtonLabel: types.maybeNull(types.string),
	ctaButtonURL: types.maybeNull(LinkURL),
	ctaButtonTreatPhoneNumberAsSMS: types.maybe(types.boolean),
	links: types.maybeNull(types.array(Link)),
	carouselCards: types.maybeNull(types.array(CarouselCard)),
	testimonialHeading: types.maybeNull(types.string),
	testimonialBody: types.maybeNull(types.string),
	testimonialAttribution: types.maybeNull(types.string),
	contactName: types.maybeNull(types.string),
	contactURL: types.maybeNull(types.string),
	contactEmailAddress: types.maybeNull(types.string),
	contactPhoneNumber: types.maybeNull(types.string),
	contactSMSNumber: types.maybeNull(types.string),
	facebookURL: types.maybeNull(types.string),
	pinterestURL: types.maybeNull(types.string),
	twitterHandle: types.maybeNull(types.string),
	instagramUsername: types.maybeNull(types.string),
	youtubeURL: types.maybeNull(types.string),
	linkedinURL: types.maybeNull(types.string),
	tikTokUsername: types.maybeNull(types.string),
	amazonURL: types.maybeNull(types.string),
	showVCard: types.maybe(types.boolean),
	companyName: types.maybeNull(types.string),
	additionalVCardNotes: types.maybeNull(types.string),
	photos: types.optional(types.map(Photo), createPhotos()),
	hideFooterEmpoweredBy: types.optional(types.boolean, false),
	hideFooterBranding: types.optional(types.boolean, false),
	overriddenThemeActive: types.optional(types.boolean, false)
}).actions(
	self => ({
		setTheme(theme, overriddenThemeActive) {
			self.theme = theme;
			self.overriddenThemeActive = overriddenThemeActive;
		},
		setHeading(heading) {
			self.heading = heading;
		},
		setBody(body) {
			self.body = body;
		},
		setVideo(hasVideo, revisionNumber) {
			self.hasVideo = hasVideo;	
			self.videoRevisionNumber = revisionNumber;
		},
		setCTAButtonLabel(label) {
			self.ctaButtonLabel = label;	
		},
		setCTAButtonURL(url) {
			self.ctaButtonURL = url;	
		},
		changeCTAButtonURLType(type) {
			if(!linkURLTypeString(self.ctaButtonURL) === type) return; // Don't do anything if it's not an actual change
			self.ctaButtonURL = createNewLinkURL(type);
		},
		setCTAButtonTreatPhoneNumberAsSMS(value) {
			self.ctaButtonTreatPhoneNumberAsSMS	= value;
		},
		setLinkLabel(id, label) {
			self.getLinkByID(id).label = label;
		},
		setLinkURL(id, url) {
			self.getLinkByID(id).url = url;
		},
		changeLinkURLType(id, type) {
			const link = self.getLinkByID(id);
			if(!link || linkURLTypeString(link.url) === type) return; // Don't do anything if it's not an actual change
			link.url = createNewLinkURL(type);
		},
		setLinkTreatPhoneNumberAsSMS(id, value) {
			self.getLinkByID(id).treatPhoneNumberAsSMS = value;
		},
		addLink(label, url, treatPhoneNumberAsSMS) {
			if(!self.links) self.links = [];
			self.links.push({ label, url, treatPhoneNumberAsSMS });
			return self.links[self.links.length - 1].id;
		},
		deleteLink(id) {
			if(!self.links) return;
			self.links = self.links.filter(link => link.id !== id);
		},
		moveLink(id, offset) {
			for(let i = 0; i < self.links.length; i++) {
				const link = self.links[i];
				if(link.id === id) {
					let targetIndex;
					if(offset === Number.NEGATIVE_INFINITY) {
						targetIndex = 0;
					} else if(offset === Number.POSITIVE_INFINITY) {
						targetIndex = self.links.length - 1;
					} else {
						targetIndex = i + offset;	
					}
					
					if(targetIndex < 0 || targetIndex > self.links.length - 1) {
						console.error("Invalid link moving offset", offset);
						return;	
					}
					const targetLink = self.links[targetIndex];

					// I haven't narrowed down the exactly what's happening, but the first pass at directly swapping the indexes caused MobX problems, so we'll instead clone+replace so that it happens in one observable operation. 
					const newArray = self.links.slice();
					newArray[targetIndex] = link;
					newArray[i] = targetLink;
					self.links.replace(newArray);
					
					break;	
				}
			}
		},
		setCarouselCardPhotoURL(id, photoURL) {
			self.getCarouselCardByID(id).photoURL = photoURL;
		},
		setCarouselCardHeading(id, heading) {
			self.getCarouselCardByID(id).heading = heading;
		},
		setCarouselCardBody(id, body) {
			self.getCarouselCardByID(id).body = body;
		},
		setCarouselCardButtonLabel(id, buttonLabel) {
			self.getCarouselCardByID(id).buttonLabel = buttonLabel;
		},
		setCarouselCardButtonURL(id, buttonURL) {
			self.getCarouselCardByID(id).buttonURL = buttonURL;
		},
		setCarouselCardTreatPhoneNumberAsSMS(id, value) {
			self.getCarouselCardByID(id).treatPhoneNumberAsSMS = value;
		},
		changeCarouselCardButtonURLType(id, type) {
			const card = self.getCarouselCardByID(id);
			if(!card || linkURLTypeString(card.buttonURL) === type) return; // Don't do anything if it's not an actual change
			card.buttonURL = createNewLinkURL(type);
		},
		addCarouselCard(photoURL, heading, body, buttonLabel, buttonURL) {
			if(!self.carouselCards) self.carouselCards = [];
			self.carouselCards.push({ photoURL, heading, body, buttonLabel, buttonURL });
			return self.carouselCards[self.carouselCards.length - 1].id;
		},
		deleteCarouselCard(index) {
			if(!self.carouselCards) return;

			// Remove the card
			self.carouselCards.splice(index, 1);
			
			// Since the photo URLs contain the card number, we need to increment the revision numbers on any remaining 2 cards to something larger than they've ever been to prevent caching issues
			const photoProperties1 = getPhotoPropertiesForCardIndex(self, 0);
			const photoProperties2 = getPhotoPropertiesForCardIndex(self, 1);
			
			const maxRevisionNumber = 1 + Math.max(photoProperties1.revisionNumber, photoProperties2.revisionNumber);
			photoProperties1.revisionNumber = maxRevisionNumber;
			photoProperties2.revisionNumber = maxRevisionNumber;
			
			self.updatePhoto(photoProperties1.id, photoProperties2);
			self.updatePhoto(photoProperties2.id, photoProperties1);			
		},
		swapCarouselCardIndex(index1, index2) {
			const cards = self.carouselCards;
			if(
				index1 >= 0 && index1 < cards.length
				&& index2 >= 0 && index2 < cards.length
				&& index1 !== index2
			) {
				const newArray = cards.slice();
				const tempCard = newArray[index2];
				newArray[index2] = newArray[index1];
				newArray[index1] = tempCard;
				cards.replace(newArray);
				
				// Now swap the photos, if any, and increment their revision number
				if(self.photos) {
					const photoProperties1 = getPhotoPropertiesForCardIndex(self, index1);
					const photoProperties2 = getPhotoPropertiesForCardIndex(self, index2);
					
					// For simplicity's sake, we'll just bump both revision numbers up past the highest one between the two photos so they're both guaranteed to update
					const maxRevisionNumber = 1 + Math.max(photoProperties1.revisionNumber, photoProperties2.revisionNumber);
					photoProperties1.revisionNumber = maxRevisionNumber;
					photoProperties2.revisionNumber = maxRevisionNumber;
					
					self.updatePhoto(photoProperties1.id, photoProperties2);
					self.updatePhoto(photoProperties2.id, photoProperties1);
				}
			}
		},
		setTestimonialPhotoURL(testimonialPhotoURL) {
			self.testimonialPhotoURL = testimonialPhotoURL;	
		},
		setTestimonialHeading(testimonialHeading) {
			self.testimonialHeading = testimonialHeading;	
		},
		setTestimonialBody(testimonialBody) {
			self.testimonialBody = testimonialBody;	
		},
		setTestimonialAttribution(testimonialAttribution) {
			self.testimonialAttribution = testimonialAttribution;	
		},
		setContactName(contactName) {
			self.contactName = contactName;
		},
		setContactURL(contactURL) {
			self.contactURL = contactURL;
		},
		setContactEmailAddress(contactEmailAddress) {
			self.contactEmailAddress = contactEmailAddress;
		},
		setContactPhoneNumber(contactPhoneNumber) {
			self.contactPhoneNumber = contactPhoneNumber;
		},
		setContactSMSNumber(contactSMSNumber) {
			self.contactSMSNumber = contactSMSNumber;
		},
		setFacebookURL(facebookURL) {
			self.facebookURL = facebookURL;
		},
		setPinterestURL(pinterestURL) {
			self.pinterestURL = pinterestURL;
		},
		setTwitterHandle(twitterHandle) {
			self.twitterHandle = twitterHandle;
		},
		setInstagramUsername(instagramUsername) {
			self.instagramUsername = instagramUsername;
		},
		setYoutubeURL(youtubeURL) {
			self.youtubeURL = youtubeURL;
		},
		setLinkedinURL(linkedinURL) {
			self.linkedinURL = linkedinURL;
		},
		setTikTokUsername(tikTokUsername) {
			self.tikTokUsername = tikTokUsername;
		},
		setAmazonURL(amazonURL) {
			self.amazonURL = amazonURL;
		},
		setShowVCard(showVCard) {
			self.showVCard = showVCard;	
		},
		setCompanyName(companyName) {
			self.companyName = companyName;	
		},
		setAdditionalVCardNotes(additionalVCardNotes) {
			self.additionalVCardNotes = additionalVCardNotes;	
		},
		updatePhoto(slot, props) {
			const photo = self.photos.get(slot);
			if(photo) {
				if(props.customization !== undefined) photo.customization = props.customization;
				if(props.revisionNumber !== undefined) photo.revisionNumber = props.revisionNumber;
				if(props.uncroppedExtension !== undefined) photo.uncroppedExtension = props.uncroppedExtension;
				if(props.cropX !== undefined) photo.cropX = props.cropX;
				if(props.cropY !== undefined) photo.cropY = props.cropY;
				if(props.cropWidth !== undefined) photo.cropWidth = props.cropWidth;
				if(props.cropHeight !== undefined) photo.cropHeight = props.cropHeight;
			}
		},
		setHideFooterEmpoweredBy(hideFooterEmpoweredBy) {
			self.hideFooterEmpoweredBy = hideFooterEmpoweredBy;	
		},
		setHideFooterBranding(hideFooterBranding) {
			self.hideFooterBranding = hideFooterBranding;	
		}
	})	
).views(
	self => ({
        getLinkByID(id) {
			if(!self.links) return null;
            return self.links.find(link => link.id === id);
		},
		getCarouselCardByID(id) {
			if(!self.carouselCards) return null;
			return self.carouselCards.find(card => card.id === id);
		},
		getPhotoSlotForCarouselCardID(id) {
			if(!self.carouselCards) return null;
			for(let i = 0; i < self.carouselCards.length; i++) {
				if(self.carouselCards[i].id === id) return photoSlotForCardIndex(i);
			}
			return null;
		},
		/*
		Checks for quickstart template-bracketed text the user hasn't filled out.
		Returns an object of the form:
		{
			for each item type that didn't pass quality checks:
				itemType: [
					{
						label: string
						itemID: string (if applicable, used for things like the links array)
					},...
				]
			}
		}
		*/
		getQualityCheckStatus() {
			function addResultItem(results, itemType, label, itemID) {
				if(!results[itemType]) results[itemType] = [];
				results[itemType].push({
					label: label,
					itemID: itemID	
				})
			}
			
			let results = {};
			
			if(
				containsTemplateReplacementBracketedString(self.heading)
				|| containsTemplateReplacementBracketedString(self.body)
			) {
				addResultItem(results, "intro-text", "Intro Text");
			}
			if(
				containsTemplateReplacementBracketedString(self.ctaButtonLabel)
				|| urlContainsTemplateReplacementBracketedString(self.ctaButtonURL)
			) {
				addResultItem(results, "cta-button", "Hot button");
			}
			if(self.links) {
				self.links.forEach((link, index) => {
					if(
						containsTemplateReplacementBracketedString(link.label)
						|| urlContainsTemplateReplacementBracketedString(link.url)
					) {
						addResultItem(results, "link", "Button " + (index + 1), link.id);
					}
				});
			}
			if(self.carouselCards) {
				self.carouselCards.forEach((card, index) => {
					if(
						containsTemplateReplacementBracketedString(card.heading)
						|| containsTemplateReplacementBracketedString(card.body)
						|| containsTemplateReplacementBracketedString(card.buttonLabel)
						|| urlContainsTemplateReplacementBracketedString(card.buttonURL)
					) {
						addResultItem(results, "carousel-card", "Carousel Card " + (index + 1), card.id);
					}
				});
			}
			if(
				containsTemplateReplacementBracketedString(self.testimonialHeading)
				|| containsTemplateReplacementBracketedString(self.testimonialBody)
				|| containsTemplateReplacementBracketedString(self.testimonialAttribution)
			) {
				addResultItem(results, "testimonial", "Social Proof");
			}
			if(
				containsTemplateReplacementBracketedString(self.contactName)
				|| containsTemplateReplacementBracketedString(self.contactURL)
				|| containsTemplateReplacementBracketedString(self.contactEmailAddress)
				|| containsTemplateReplacementBracketedString(self.contactPhoneNumber)
				|| containsTemplateReplacementBracketedString(self.contactSMSNumber)
				|| containsTemplateReplacementBracketedString(self.companyName)
				|| containsTemplateReplacementBracketedString(self.additionalVCardNotes)
			) {
				addResultItem(results, "contact-details", "Virtual Biz Card");
			}
			if(
				containsTemplateReplacementBracketedString(self.facebookURL)
				|| containsTemplateReplacementBracketedString(self.pinterestURL)
				|| containsTemplateReplacementBracketedString(self.twitterHandle)
				|| containsTemplateReplacementBracketedString(self.instagramUsername)
				|| containsTemplateReplacementBracketedString(self.youtubeURL)
				|| containsTemplateReplacementBracketedString(self.linkedinURL)
				|| containsTemplateReplacementBracketedString(self.tikTokUsername)
				|| containsTemplateReplacementBracketedString(self.amazonURL)
			) {
				addResultItem(results, "contact-social-media-list", "Social Accounts");
			}
			
			return results;
		}
	})	
);



// The transient editor state
const InterfaceStateStore = types.model({
	// Whether the user has a paid plan
	hasPaidPlan: types.boolean,
	// Whether the user is in the editor mode
	isEditing: types.boolean,
	// Whether the user is in the mode where they choose themes (and maybe eventually change the overall design)
	themeGalleryOpen: types.boolean,
	// If defined, which full-page theme they're previewing
	previewingTheme: types.maybeNull(types.enumeration(Themes)),
	previewingThemeIsOverriddenTheme: types.optional(types.boolean, false),
	// If defined, which full-page theme they're "shopping" for in a pre-signup mode
	shoppingTheme: types.maybeNull(types.enumeration(Themes)),
	// Represents the type and ID (if it's an item with an ID) of the item currently being edited, or null if nothing's being edited
	currentlyEditingItemType: types.maybeNull(types.enumeration(["intro-photo", "video", "intro-text", "cta-button", "link", "carousel-card", "testimonial", "contact-details", "contact-social-media-list", "footer"])),
	currentlyEditingItemID: types.maybeNull(types.string),
	currentlyEditingPhotoSlot: types.maybeNull(types.enumeration(PhotoSlots)),
	serverSyncInProgressType: types.maybeNull(types.enumeration(["save", "video", "photo", "cardPhotoSwap", "cardPhotoDelete", "file", "fileDelete", "setBioURL"])),
	saveState: types.optional(types.enumeration(["saving", "saved", "error"]), "saved"),
	viewingVideo: types.boolean,
	showingVideoPromoPopup: types.boolean,
	showingSharePopup: types.boolean,
	showingQuestionLinkURL: types.maybeNull(LinkURLQuestion),
	showingQuestionSelectedAnswer: types.optional(types.string, ""),
	showingQuestionEmailAddress: types.optional(types.string, ""),
	firstRun: types.boolean, // Whether it's the first run of the editor, meaning we should show the introductory popup
	previewingForShare: types.boolean, // Whether they're doing a final preview of their bio before sharing
	userLevel: types.string,
	womanOwnedBusiness: types.boolean,
	bioAuthorName: types.string,
	showBetaFeatures: types.boolean,
	myBioWithTheme: types.maybeNull(types.enumeration(Themes)),
	themeWasSwitchedTo: types.boolean,
	viewedVideo: types.boolean,
	showingUploadID: types.maybeNull(types.integer),
	showingUploadFileType: types.maybeNull(UploadFileType),
	primaryGoal: types.enumeration(PrimaryGoals),
	doneScreenPhase: types.optional(types.enumeration(DoneScreenPhase), "none"),
	hasSetURL: types.optional(types.boolean, false),
	hasSharedViaEmail: types.optional(types.boolean, false),
	hasSharedViaSocial: types.optional(types.boolean, false),
	availableDomains: types.array(types.string),
	selectedDomain: types.string,
	selectedNicename: types.string,
	referralURL: types.string,
	upgradeToProURL: types.string,
	urlCopied: types.optional(types.boolean, false),
	isCloneSource: types.boolean,
	cloneSourceTheme: types.maybeNull(types.enumeration(Themes)),
	cloneSourceThemeTitle: types.string,
	introState: types.optional(types.enumeration(IntroState), "none"),
}).actions(
	self => ({
		setIsEditing(value) {
			self.isEditing = value;	
		},
		setPreviewingTheme(theme, isOverriddenTheme) {
			self.previewingTheme = theme;
			self.previewingThemeIsOverriddenTheme = isOverriddenTheme;
		},
		setThemeGalleryOpen(value) {
			self.themeGalleryOpen = value;
		},
        setCurrentlyEditing(type, id, photoSlot) {
			self.currentlyEditingItemType = type;
			self.currentlyEditingItemID = id;
			self.currentlyEditingPhotoSlot = photoSlot;
		},
		setServerSyncInProgress(type) {
			self.serverSyncInProgressType = type;	
		},
		setViewingVideo(value) {
			self.viewingVideo = value;
			if(value) self.viewedVideo = true;
		},
		setShowingVideoPromoPopup(value) {
			self.showingVideoPromoPopup = value;
		},
		setShowingSharePopup(value) {
			self.showingSharePopup = value;	
		},
		setPreviewingForShare(value) {
			self.previewingForShare = value;
		},
		setSaveState(state) {
			self.saveState = state;	
		},
		setThemeWasSwitchedTo(value) {
			self.themeWasSwitchedTo = value;
		},
		setShowingQuestionLinkURL(url) {
			self.showingQuestionLinkURL = url;
		},
		setShowingQuestionSelectedAnswer(answer) {
			self.showingQuestionSelectedAnswer = answer;	
		},
		setShowingQuestionEmailAddress(emailAddress) {
			self.showingQuestionEmailAddress = emailAddress;	
		},
		setShowingUpload(id, fileType) {
			self.showingUploadID = id;
			self.showingUploadFileType = fileType;
		},
		setDoneScreenPhase(value) {
			if(self.doneScreenPhase !== value) {
				if(self.doneScreenPhase === "none") {
					// Clear out the item being edited when a done screen is being launched to avoid overlap issues
					self.setCurrentlyEditing(null, null, null);
				}
				self.doneScreenPhase = value;
			}
		},
		setHasSetURL(value) {
			self.hasSetURL = value;	
		},
		setHasSharedViaEmail(value) {
			self.hasSharedViaEmail = value;	
		},
		setHasSharedViaSocial(value) {
			self.hasSharedViaSocial = value;	
		},
		setSelectedDomain(value) {
			self.selectedDomain = value;
		},
		setSelectedNicename(value) {
			self.selectedNicename = value;
		},
		setURLCopied(value) {
			self.urlCopied = value;	
		},
		setIntroState(value) {
			self.introState = value;
		}
	})
).views(
	self => ({
        getURL() {
			return `https://${self.selectedDomain}/${self.selectedNicename}`;
		}
	})	
);

function createNewLinkURL(type) {
	switch(type) {
		case "url":
			return ""
		case "question":
			return LinkURLQuestion.create({ type: "question" });
		case "upload":
			return LinkURLUpload.create({ type: "upload" });
		default:
			return null;
	}
}

function linkURLTypeString(linkURL) {
	if(linkURL === null || typeof(linkURL) === "string") {
		return "url";	
	} else {
		return linkURL.type;	
	}
}

function contentForDisplayMode(displayMode, bioStoreContent, defaultContent, canUseDefaultWhenEditing = false) {
	let useDefault = false;
	switch(displayMode) {
		case "viewing":
			useDefault = false;
			break;
		case "editing":
			// We're viewing/editing their actual bio, not sample preview content
			useDefault = canUseDefaultWhenEditing;
			break;
		case "previewing":
			// We're previewing, so use their real bio content if available, or fall back to the default content if not
			useDefault = true;
			break;
		default:
	}
	
	if(useDefault) {
		return bioStoreContent !== null ? bioStoreContent : defaultContent;
	} else {
		return bioStoreContent;
	}
}

function createIDGenerator(type) {
	return () => generateID(type);
}

function createPhotos() {
	return () => {
		const photos = {};
		PhotoSlots.forEach(slot => {
			photos[slot] = Photo.create({ id: slot });
		});
		return photos;
	}
}

function generateID(type) {
	return `${type}-${uuidv4()}`;
	
	// From https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
	function uuidv4() {
		// eslint-disable-next-line
		return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
	  }
}

function photoSlotForCardIndex(index) {
	return "card" + (index + 1) + "-photo";	
}

function cardIndexForPhotoSlot(slot) {
	return parseInt(slot.replace("card", "").replace("-photo", ""), 10) - 1;
}

function getPhotoPropertiesForCardIndex(bioStore, index) {
	const slot = photoSlotForCardIndex(index);
	const photoObject = bioStore.photos.get(slot);
	if(photoObject) {
		return toJS(photoObject); 
	} else {
		// Provide default values for a missing photo
		return {
			id: "card" + (index + 1) + "-photo",
			customization: "useDefault",
			revisionNumber: 0,
			uncroppedExtension: "jpg",
			cropX: 0,
			cropY: 0,
			cropWidth: 0,
			cropHeight: 0
		};	
	}
}

// Whether an "overridden" theme (where the clone source has customized their selected theme with CSS) is active, meaning we should apply .overridden-theme-active for CSS to target
function overriddenThemeActive(bioStore, interfaceStateStore) {
	if(interfaceStateStore.isCloneSource) return true; // Clone sources are always considered to be in overridden-theme mode
	if(bioStore.overriddenThemeActive) return true; // They're not a clone source, so the overridden theme is only active if explicitly set to be
	return false;
}

export {BioStore, InterfaceStateStore, Themes, RetiredThemes, ThemeProperties, Link, LinkURLQuestion, LinkURLUpload, contentForDisplayMode, photoSlotForCardIndex, cardIndexForPhotoSlot, sanitizeThemeValue, linkURLTypeString, getPhotoPropertiesForCardIndex, overriddenThemeActive};