import React from 'react';
import { observer } from "mobx-react-lite";
import { Modal, Input } from 'rsuite';
import header1 from './images/done-popup-header-1.png';
import header2 from './images/done-popup-header-2.png';
import header3 from './images/done-popup-header-3.png';
import headerCheckmark from './images/done-popup-header-checkmark.png';
import mailtoForGoal from './DoneEmails';
import copy from './CopyUtil';

const DonePopup = observer(({
	bioStore,
	interfaceStateStore,
	serverSynchronization
}) => {
	function onDone() {
		interfaceStateStore.setDoneScreenPhase("none");
	}
	
	function setSelectedNicename(value) {
		const filteredValue = value.replace(/[^a-zA-Z0-9-]/g, ''); // Filter out everything but alphanumeric/hyphens for their URLs
		interfaceStateStore.setSelectedNicename(filteredValue);
	}

	const showPanel = (interfaceStateStore.doneScreenPhase === "done");
	
	let publishFields = null;
	let publishButton = null;
	let emailItems = null;
	let shareItems = null;
	if(!interfaceStateStore.hasSetURL) {
		let suggestedExample = "";
		switch(interfaceStateStore.primaryGoal) {
			case "bio":
				suggestedExample = "Ex: Your name"
				break;
			case "quickclick":
				suggestedExample = "Ex: Your event title"
				break;
			case "quiz":
				suggestedExample = "Ex: Your quiz title"
				break;
			case "informational":
				suggestedExample = "Ex: What your link is about"
				break;
			default:
				break;
		}
		publishFields = (<>
			<div className="edit-panel-field">
				<label className="done-field-label">Select your domain:</label>	
				<select
					value={interfaceStateStore.selectedDomain}
					onChange={evt => interfaceStateStore.setSelectedDomain(evt.target.value)}
				>
					{interfaceStateStore.availableDomains.map(domain =>
						<option value={domain}>{domain}</option>
					)};
				</select>
			</div>
			<div className="edit-panel-field">
				<label className="done-field-label">Customize your link:</label>
				<Input 
					placeholder={suggestedExample}
					value={interfaceStateStore.selectedNicename}
					onChange={setSelectedNicename}
				/>
			</div>	
		</>);
		publishButton = (<button
			onClick={() => serverSynchronization.setBioURL(interfaceStateStore.selectedDomain, interfaceStateStore.selectedNicename)}
		>
			Publish Link
		</button>);
	} else {
		if(!interfaceStateStore.hasSharedViaEmail) {
			emailItems = (<>
				<p className="done-body-description">Email 3 friends to show off your link and give them a special discount.</p>
				<a
					href={mailtoForGoal(interfaceStateStore.primaryGoal, interfaceStateStore.getURL(), interfaceStateStore.referralURL)}
					target="_blank"
					rel="noreferrer"
					onClick={() => {
						interfaceStateStore.setHasSharedViaEmail(true);
						serverSynchronization.setSharedStatus("email");
					}}
				>
					<button>Email Now</button>
				</a>
				</>);
		}
		if(!interfaceStateStore.hasSharedViaSocial) {
			shareItems = (<>
				<p className="done-body-description">Share your new link on social and tag us @bossladybio</p>
				<button
					onClick={() => copy(interfaceStateStore)}
				>
					Copy my link
				</button>
				<a
					href="https://www.instagram.com/"
					target="_blank"
					rel="noreferrer"
					onClick={() => {
						interfaceStateStore.setHasSharedViaSocial(true);
						serverSynchronization.setSharedStatus("social");
					}}
				>
					<button>Share on IG</button>
				</a>
			</>);
		}
	}
	
	let successItems = null;
	if(interfaceStateStore.hasSetURL && interfaceStateStore.hasSharedViaEmail && interfaceStateStore.hasSharedViaSocial) {
		successItems = (<>
			<p className="done-success-description">Congratulations, you are off to a great start!</p>
			<p className="done-success-description">We are so proud of you! If you want to check out more ways to share your link, click the <span className="done-share-button-example">Share button</span> at the top to learn more. </p>
		</>);
	}
		
	return (
		<Modal show={showPanel} className="edit-panel done-popup-panel" overflow={false}>
			<Modal.Body className="edit-panel-body">
				<button onClick={onDone} className="done-close-button">Close <i className="fa-solid fa-xmark"></i></button>
				<h1>Yay, you did it!</h1>
				<p className="done-description">High Five! 👋🏻 You're almost there.</p>
				<p className="done-description">Follow these 3 easy steps to make your link official:</p>
				<h2>
					<CounterCheckmark counter={1} showCheckmark={interfaceStateStore.hasSetURL}></CounterCheckmark>
					Publish Your Link
				</h2>
				{ publishFields }
				<p className="done-body-description">Your link: <span className="done-link-preview">{interfaceStateStore.selectedDomain}/{interfaceStateStore.selectedNicename}</span></p>
				{ publishButton }
				<h2>
					<CounterCheckmark counter={2} showCheckmark={interfaceStateStore.hasSharedViaEmail}></CounterCheckmark>
					Share Your Link
				</h2>
				{ emailItems }
				<h2>
					<CounterCheckmark counter={3} showCheckmark={interfaceStateStore.hasSharedViaSocial}></CounterCheckmark>
					Create a Buzz
				</h2>
				{ shareItems }
				{ successItems }
			</Modal.Body>
		</Modal>
	)
});

const CounterCheckmark = observer(({ counter, showCheckmark }) => {
	let className = null;
	let image = null;
	
	if(showCheckmark) {
		className = "done-header-checkmark";
		image = headerCheckmark;
	} else {
		className = "done-header-counter";
		switch(counter) {
			case 1: image = header1; break;
			case 2: image = header2; break;
			default: image = header3; break;	
		}
	}
	return <img src={image} className={className} alt=""></img>;
});
 
export default DonePopup;
